

























































































































import { Component, Vue } from 'vue-property-decorator'
import { apiAdminList, apiAdminDelete, apiRoleList, apiAdminEdit } from '@/api/setting/permissions'
import { PageMode } from '@/utils/type'
import { RequestPaging } from '@/utils/util'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import { AdminList_Req } from '@/api/setting/permissions.d.ts'
import ExportData from '@/components/export-data/index.vue'

@Component({
    components: {
        LsDialog,
        LsPagination,
        ExportData
    }
})
export default class Admin extends Vue {
    /** S Data **/
    apiAdminList = apiAdminList
    // 表单数据
    form: AdminList_Req = {
        account: '', //账号
        name: '', //名称
        role_id: undefined //角色id
    }
    pager: RequestPaging = new RequestPaging()
    roleList: Array<object> = [] // 角色列表

    /** E Data **/

    /** S Methods **/
    // 搜索角色
    search() {
        this.pager.page = 1
        this.getAdminList()
    }

    // 重置搜索
    resetSearch() {
        Object.keys(this.form).map(key => {
            this.$set(this.form, key, '')
        })
        this.getAdminList()
    }

    // 获取管理员列表
    getAdminList() {
        // 请求管理员列表
        this.pager
            .request({
                callback: apiAdminList,
                params: this.form
            })
            .catch(() => {})
    }

    // 获取角色列表
    getRoleList() {
        apiRoleList({
            page_type: 1
        }).then(res => {
            this.roleList = res.lists
        })
    }

    // 添加管理员
    addAdmin() {
        this.$router.push({
            path: '/setting/permissions/admin_edit',
            query: {
                mode: PageMode.ADD
            }
        })
    }

    // 删除管理员
    onAdminDelete(e: any) {
        apiAdminDelete({ id: e.id }).then(() => {
            // 删除成功就请求新列表
            this.getAdminList()
            this.$message.success('删除成功!')
        })
    }

    // 编辑管理员
    goAdminEdit(item: any) {
        const isAdd: any = false
        this.$router.push({
            path: '/setting/permissions/admin_edit',
            query: {
                mode: PageMode.EDIT,
                id: item.id
            }
        })
    }

    // 更改管理员开关
    changeDisableSwitchStatus(value: 0 | 1, data: any) {
        apiAdminEdit({
            id: data.id,
            account: data.account,
            name: data.name,
            role_id: data.role_id,
            disable: data.disable,
            multipoint_login: data.multipoint_login
        }).finally(() => {
            this.getAdminList()
        })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getAdminList()
        this.getRoleList()
    }

    /** E Life Cycle **/
}
